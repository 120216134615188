import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import {debounce, pwAssetUrl} from "../utils/etc";
import AppContext from "../contexts/AppContext";
import {logEvent, userEvents} from "../utils/log";
import {webviewAnalyticsEvent, webviewPreloadRewardedAd} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import processingManager from "../photolab/ProcessingManager";
import FileChooseButton from "../components/FileChooseButton";
import Processing from "../photolab/Processing";

const video = {
  url: pwAssetUrl(`mylooks/avatar_ai_motion_banner_1_v3.mp4`),
  width: 1290,
  height: 1290,
}

export default class IndexPage extends React.Component {

  state = {};

  componentDidMount() {
    if (clientStorage.getShouldRedirectToResult()) {
      const restoredProcessing = processingManager.restore();
      if (restoredProcessing != null) {
        const startedAt = restoredProcessing.getExtra(Processing.EXTRA_STARTED_AT);
        if (startedAt + 3_600_000 >= Date.now()) {
          this.props.history.replace(routes.RESULT);
          return;
        }
      }
    }

    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("IndexPage.handleWebviewTabSelected", 300, () => {
          logEvent(userEvents.PAGE_INDEX);
        });
      }
    }, true);

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_INDEX);
    }

    this.context.hideLoader();
  }

  handleFileSelected = (file) => {
    clientStorage.incrementSelectedPhotosAmount();

    webviewAnalyticsEvent("photo_selected", [
      "",
      "index",
      clientStorage.getSelectedPhotosAmount() + 1,
      1
    ]);

    if (window.clientConfig.features.isRewardedAdModel) {
      webviewPreloadRewardedAd(window.clientConfig.features.rewardedAdUnitId);
    }

    this.context.showLoader(false, () => {
      this.props.history.push(routes.UPLOAD, {files: [file]});
    });
  };

  handleFileSelectButtonClick = () => {
    webviewAnalyticsEvent("button_tapped", [
      "choose_photo",
      "index",
    ]);
  };

  handleMultiPhotoButtonClick = () => {
    this.props.history.push(routes.PHOTOCHOOSER);
  };

  renderDefault = () => {
    const classNames = ["main-page"];
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const videoHeight = (video.height * (vw / video.width));

    return <main className={classNames.join(" ")}>
      <div className="container">
        <div className="content-container">

          <div className="content-video">
            <video
              style={{height: `${videoHeight}px`}}
              className="main-video"
              playsInline
              autoPlay
              loop
              muted>
              <source type="video/mp4" src={video.url} />
            </video>
          </div>

          <h1 dangerouslySetInnerHTML={{__html: i18n.t("index_title")}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("index_subtitle")}} />

          <FileChooseButton
            hidden={window.clientConfig.features.isMultiPhoto}
            className="btn-upload-foto"
            onFilesSelected={(files) => this.handleFileSelected(files[0])}
            onClick={this.handleFileSelectButtonClick}
            children={i18n.t("upload_choose_photo_button")} />

          <button
            hidden={!window.clientConfig.features.isMultiPhoto}
            className="btn-upload-foto"
            onClick={this.handleMultiPhotoButtonClick}
            children={i18n.t("upload_choose_photo_button")} />
        </div>
      </div>
    </main>;
  }

  render() {
    return this.renderDefault();
  }
}

IndexPage.contextType = AppContext;
